// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-notice-tsx": () => import("/opt/build/repo/src/templates/notice.tsx" /* webpackChunkName: "component---src-templates-notice-tsx" */),
  "component---src-templates-company-tsx": () => import("/opt/build/repo/src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-audition-index-tsx": () => import("/opt/build/repo/src/templates/audition/index.tsx" /* webpackChunkName: "component---src-templates-audition-index-tsx" */),
  "component---src-templates-notice-index-tsx": () => import("/opt/build/repo/src/templates/notice-index.tsx" /* webpackChunkName: "component---src-templates-notice-index-tsx" */),
  "component---src-templates-artist-index-tsx": () => import("/opt/build/repo/src/templates/artist/index.tsx" /* webpackChunkName: "component---src-templates-artist-index-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artist-tsx": () => import("/opt/build/repo/src/pages/artist.tsx" /* webpackChunkName: "component---src-pages-artist-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-submit-audition-tsx": () => import("/opt/build/repo/src/pages/submit-audition.tsx" /* webpackChunkName: "component---src-pages-submit-audition-tsx" */),
  "component---src-pages-submit-success-tsx": () => import("/opt/build/repo/src/pages/submit-success.tsx" /* webpackChunkName: "component---src-pages-submit-success-tsx" */)
}

