import path from 'path';
import Slugger from 'github-slugger';
const slugger = new Slugger();
export const I18N_CONFIG = {
    canon: 'ko',
    languages: ['ko', 'en', 'ja', 'zh'],
};
/**
 * Create Language pages for pages in `src/pages/`
 * This function has to be run inside `onCreatePage` hook
 * @param options.page `page` from Gatsby's onCreatePage
 * @param options.createPage `actions.createPage` from Gatsby's onCreatePage
 */
export const createIndexPageWithLang = ({ page, createPage, deletePage, }) => {
    const { componentPath, path } = page;
    if (!componentPath || !componentPath.includes('src/pages'))
        return;
    I18N_CONFIG.languages.forEach(lang => {
        const newPage = {
            ...page,
            path: `${lang}${path}`,
            context: {
                lang,
            },
        };
        // @ts-ignore
        createPage(newPage);
    });
    const canonPageWithContext = {
        ...page,
        context: {
            lang: I18N_CONFIG.canon,
        },
    };
    deletePage(page);
    createPage(canonPageWithContext);
};
/**
 * Create slug for remark page based on its language field
 * Has to be run inside Gatsby's `onCreateNode`
 * @param options.node A Remark Node
 * @param options.getNode Gatsby method from actions
 * @param options.createNodeField Gatsby method
 * @param options.typeWithNoTitle List of type that
 * won't need slug based on title
 */
export const createI18nSlugForRemark = ({ node, createNodeField, }) => {
    if (!node) {
        return;
    }
    const { frontmatter: { title, lang, type } } = node;
    const [contentType, typeName] = type.split('/');
    let slug;
    if (contentType !== 'content') {
        slug = `/${lang}/${typeName}/`;
    }
    else {
        slug = `/${lang}/${typeName}/${slugger.slug(title).substring(0, 16)}`;
    }
    createNodeField({
        node,
        name: 'slug',
        value: slug,
    });
};
/**
 * Create language pages for Remark nodes
 * Has to be run inside Gatsby's `createPage`
 *
 * @param options.createPage Gatsby's method
 * @param options.layoutPath relative path to template component
 * @param options.nodes data returned by Graphql query for these remark nodes
 * @param options.createCanonPath a string that'll be used as the path for
 * canon page (no language prefixes)
 */
export const createRemarkI18nPages = ({ createPage, layoutPath, nodes, createCanonPath, }) => {
    const componentPath = path.resolve(layoutPath);
    nodes.forEach(({ fields, frontmatter }) => {
        const { lang } = frontmatter;
        if (createCanonPath && lang === I18N_CONFIG.canon) {
            createPage({
                path: createCanonPath,
                component: componentPath,
                context: {
                    // still pass in slug so it's queriable
                    slug: fields.slug,
                    lang,
                },
            });
        }
        createPage({
            path: fields.slug,
            component: componentPath,
            context: {
                slug: fields.slug,
                lang,
            },
        });
    });
};
/**
 * Create Language pages for special data node.
 * Artist node doesn't have different node for diferent language
 * but store language inside each fields. (artist.nameKo, artist.nameEn, etc.)
 * @param options.createPage Gatsby method
 * @param options.layoutPath relative path from root to template component
 * @param options.nodes data returned by Graphql query for these special nodes
 */
export const createI18nPages = ({ createPage, layoutPath, nodes, }) => {
    const componentPath = path.resolve(layoutPath);
    nodes.forEach(({ fields }) => {
        createPage({
            path: fields.slug,
            component: componentPath,
            context: {
                slug: fields.slug,
                lang: I18N_CONFIG.canon,
            },
        });
        I18N_CONFIG.languages.forEach(lang => {
            const slug = `/${lang}${fields.slug}`;
            createPage({
                path: slug,
                component: componentPath,
                context: {
                    slug: fields.slug,
                    lang,
                },
            });
        });
    });
};
