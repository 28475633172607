import * as React from 'react';
import { useRef } from 'react';
import pose from 'react-pose';
import styled from '@emotion/styled';
import { LangSelector } from '../lang-selector';
import { Link } from '../wrapper-lang';
import { mqs } from '../style-utils';
export const TRANSITION = {
    ease: 'easeOut',
};
const PoseLi = pose.li({
    hoverable: true,
    init: {
        x: 0,
        transition: TRANSITION,
    },
    hover: {
        x: 5,
        transition: TRANSITION,
    },
    hidden: {
        x: -25,
        transition: TRANSITION,
    },
    show: {
        x: 0,
        transition: TRANSITION,
    },
});
const StyledUl = styled.ul `
  z-index: 200;

  &.is-hovering li a {
    opacity: 0.4;
  }

  li a:hover {
    opacity: 1;
  }

  li a {
    display: block;
    position: relative;
    font-weight: bold;
    font-size: 1.8rem;
    letter-spacing: -0.05rem;
    line-height: 1.3;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
    color: ${({ theme }) => theme.fg};
    transition: opacity 0.5s ease;

    &.is-active {
      opacity: 0.4;
    }
  }

  ${mqs[2]} {
    li a {
      font-size: 1.6rem;
    }
  }
`;
const MenuLink = ({ children, ...props }) => {
    const linkRef = useRef(null);
    React.useEffect(() => {
        const $a = linkRef.current;
        if (!$a)
            return;
        const toggleClass = () => {
            const $parent = $a.closest('ul');
            $parent.classList.toggle('is-hovering');
        };
        $a.addEventListener('mouseover', toggleClass);
        $a.addEventListener('mouseleave', toggleClass);
        return () => {
            $a.removeEventListener('mouseover', toggleClass);
            $a.removeEventListener('mouseleave', toggleClass);
        };
    });
    return (<PoseLi>
      
      <Link ref={linkRef} activeClassName="is-active" {...props}>
        {children}
      </Link>
    </PoseLi>);
};
export const MenuUl = props => (<StyledUl {...props}>
    <MenuLink to="/artist/">Artist</MenuLink>
    <MenuLink to="/company/">Company</MenuLink>
    <MenuLink to="/notice/">Notice</MenuLink>
    <MenuLink to="/audition/">Audition</MenuLink>
    <li>
      <LangSelector />
    </li>
  </StyledUl>);
